body {
	font-family: "SF Display";
	font-weight: 400;
	font-size: 12px;
	height: 100%;
}

.login-body {
	background: #e9ebee;
	/* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #e9ebee, #edeff2);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, #e9ebee, #edeff2);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.login-block {
	width: 100%;
}

.banner-sec {
	background: url(../img/form-bg.jpg) no-repeat left bottom;
	background-size: cover;
	min-height: 550px;
	border-radius: 0 10px 10px 0;
	padding: 0;
}

.container {
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .06), 0 2px 15px 0 rgba(0, 0, 0, .07);
}


.login-sec {
	position: relative;
	padding-top: 10px;
}

/* .facebook-connect-btn {
	margin-top: 10px;
} */

.orholder {
	margin: 10px auto;
	text-align: center;
	font-size: 16px;
}

.facebook-connect-btn button {
	height: 50px;
	background: none;
	border: none;
	display: block;
	border-radius: 5px;
	color: white;
	font-size: 16px;
	margin: 15px auto;
	width: 225px;
	background-color: #4267b2;
	outline: none;
}

.fb_svg {
	width: 24px;
	height: 24px;
	float: left;
	margin-left: 5px;
}

.login-sec .copy-text {
	margin-top: 20px;
	font-size: 14px;
	text-align: center;
}

.sfp {
	margin: 20px auto;
	text-align: center;
	cursor: pointer;
}

.login-sec .copy-text i {
	color: #0084ff;
}

.login-sec .copy-text a {
	color: #0084FF;
}

.login-sec .copy-text a img {
	width: 59px;
	margin-left: 5px;
	margin-top: -2px;
}

.login-sec span {
	display: inline-block;
}

.login-sec h2.title {
	margin-bottom: 30px;
	font-weight: 800;
	font-size: 30px;
	color: #0072ff;
}

.login-sec h2.title:after {
	background: #00c6ff;
	/* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #0072ff, #00c6ff);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, #0072ff, #00c6ff);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	content: " ";
	width: 100px;
	height: 5px;
	display: block;
	margin-top: 20px;
	border-radius: 3px;
	margin-left: auto;
	margin-right: auto
}


.login-sec h2 {
	margin-bottom: 30px;
	font-weight: 800;
	font-size: 30px;
	color: #DE6262;
}

.login-sec h2:after {
	content: " ";
	width: 100px;
	height: 5px;
	background: #FEB58A;
	display: block;
	margin-top: 20px;
	border-radius: 3px;
	margin-left: auto;
	margin-right: auto
}

.login-sec span.fb-login-icon {
	position: relative;
	top: -7px;
	left: 0;
	margin-left: 10px;
}

.btn-login {
	background: #0072ff;
	color: #fff;
	font-weight: 600;
	width: 100%;
}

.btn-login:active,
.btn-login:focus,
.btn-login:hover {
	background: #0072ff;
	color: #fff;
	font-weight: 600;
}

.banner-text {
	width: 70%;
	position: absolute;
	bottom: 40px;
	padding-left: 20px;
}

.banner-text h2 {
	color: #fff;
	font-weight: 600;
}

.banner-text h2:after {
	content: " ";
	width: 100px;
	height: 5px;
	background: #FFF;
	display: block;
	margin-top: 20px;
	border-radius: 3px;
}

.banner-text p {
	color: #fff;
}

.radio {
	display: inline-block;
	position: relative;
	padding-left: 20px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 12px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-right: 10px;
}

/* Hide the browser's default radio button */
.radio input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	top: 0;
	display: inline-block;
	height: auto;
	width: auto;
}

/* Create a custom radio button */
.checkround {
	position: absolute;
	top: -1px;
	left: 0;
	height: 15px;
	width: 15px;
	background-color: #fff;
	border-color: #0084FF;
	border-style: solid;
	border-width: 2px;
	border-radius: 50%;
}


/* When the radio button is checked, add a blue background */
.radio input:checked~.checkround {
	background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkround:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio input:checked~.checkround:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.radio .checkround:after {
	left: 2px;
	top: 2px;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: #0084FF;


}

.red {
	color: red;
}

.pt {
	padding-top: 50px !important;
}

.mt {
	margin-top: 20px !important;
}

.error {
	border-color: red;
}

.input-feedback {
	margin-top: 5px;
	color: red;
}

.footer {
	background: transparent !important;
	border-radius: 0px !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	padding-top: 8px;
	padding-right: 0px !important;
	padding-left: 0px !important;
	color: #666;
}

.footer a {
	text-decoration: none;
	color: #666;
}

.footer a:link {
	color: #666;
	text-decoration: none;
}

.footer a:visited {
	color: #666;
	text-decoration: none;
}

.footer a:hover {
	color: #666;
	text-decoration: none;
}

.footer a:active {
	color: #666;
	text-decoration: none;
}