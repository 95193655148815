/** Black */
@font-face {
  font-family: "SF Display";
  font-weight: 900;
  src: url("../fonts/SanFranciscoFont/SF-UI-Display-Black.otf");
  font-display: block;
}

/** Bold */
@font-face {
  font-family: "SF Display";
  font-weight: 700;
  src: url("../fonts/SanFranciscoFont/SF-UI-Display-Bold.otf");
  font-display: block;
}

/** Heavy */
@font-face {
  font-family: "SF Display";
  font-weight: 800;
  src: url("../fonts/SanFranciscoFont/SF-UI-Display-Heavy.otf");
  font-display: block;
}

/** Light */
@font-face {
  font-family: "SF Display";
  font-weight: 200;
  src: url("../fonts/SanFranciscoFont/SF-UI-Display-Light.otf");
  font-display: block;
}

/** Medium */
@font-face {
  font-family: "SF Display";
  font-weight: 500;
  src: url("../fonts/SanFranciscoFont/SF-UI-Display-Medium.otf");
  font-display: block;
}

/** Regular */
@font-face {
  font-family: "SF Display";
  font-weight: 400;
  src: url("../fonts/SanFranciscoFont/SF-UI-Display-Regular.otf");
  font-display: block;
}

/** Semibold */
@font-face {
  font-family: "SF Display";
  font-weight: 600;
  src: url("../fonts/SanFranciscoFont/SF-UI-Display-Semibold.otf");
  font-display: block;
}

/** Thin */
@font-face {
  font-family: "SF Display";
  font-weight: 300;
  src: url("../fonts/SanFranciscoFont/SF-UI-Display-Thin.otf");
  font-display: block;
}

/** Ultralight */
@font-face {
  font-family: "SF Display";
  font-weight: 100;
  src: url("../fonts/SanFranciscoFont/SF-UI-Display-Ultralight.otf");
  font-display: block;
}