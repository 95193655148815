body {
	font-family: "SF Display";
	font-weight: 400;
	font-size: 12px;
}

input[type="text"]:disabled,
select:disabled,
textarea:disabled {
	background: #FFFFFF;
}

.main-body {
	background-color: #E9EBEE;
}

/*************************************************************************
General Styles
**************************************************************************/
.mCSB_container {
	margin-right: 0px !important;
}

.mCSB_scrollTools {
	width: 5px !important;
}

.loading {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	z-index: 99999999999;
	display: none;

}

.loading>div {
	width: 60px;
	height: 60px;
	position: absolute;
	left: 50%;
	margin-left: -30px;
	top: 50%;
	margin-top: -30px;
}

.skype-loader {
	width: 80px;
	height: 80px;
	position: relative;
}

.skype-loader .dot {
	position: absolute;
	top: 0;
	left: 0;
	width: 80px;
	height: 80px;

	animation: 1.7s dotrotate cubic-bezier(0.775, 0.005, 0.310, 1.000) infinite;
}

.skype-loader .dot:nth-child(1) {
	animation-delay: 0.2s;
}

.skype-loader .dot:nth-child(2) {
	animation-delay: 0.35s;
}

.skype-loader .dot:nth-child(3) {
	animation-delay: 0.45s;
}

.skype-loader .dot:nth-child(4) {
	animation-delay: 0.55s;
}

.skype-loader .dot:after,
.skype-loader .dot .first {
	content: "";
	position: absolute;
	width: 8px;
	height: 8px;
	background: white;
	border-radius: 50%;
	left: 50%;
	margin-left: -4px;
}

.skype-loader .dot .first {
	background: #fff;
	margin-top: -4px;
	animation: 1.7s dotscale cubic-bezier(0.775, 0.005, 0.310, 1.000) infinite;
	animation-delay: 0.2s;
}

@keyframes dotrotate {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@keyframes dotscale {

	0%,
	10% {
		width: 16px;
		height: 16px;
		margin-left: -8px;
		margin-top: -4px;
	}

	50% {
		width: 8px;
		height: 8px;
		margin-left: -4px;
		margin-top: 0;
	}

	90%,
	100% {
		width: 16px;
		height: 16px;
		margin-left: -8px;
		margin-top: -4px;
	}
}


.listenyzen-bar {
	margin: 20px 0;
	border-color: #eee;
}

.notification-message .Toastify__toast-body {
	font-family: "SF Display";
	font-size: 14px;
}

/*Menu Styles*/

.nav-pills>li>a {
	border-radius: 0;
}


#menu-toggle-2 {
	margin: 0;
	padding: 0;
	cursor: pointer;
	background: #fff;
	color: #999;
	border: 0px;
	font-size: 20px;
	margin-top: 15px;
}

#menu-toggle-2 span {
	display: block;
}

#wrapper {
	padding-left: 0;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	overflow: hidden;
}

#wrapper.toggled {
	padding-left: 250px;
	overflow: hidden;
}

.navbar.navbar-default.listenyzen-navbar {
	background: #fff;
	margin-bottom: 0;
}

.navbar.navbar-default.listenyzen-navbar .navbar-brand {
	height: 40px;
	padding: 6px 0 0 10px;
}

.navbar.navbar-default.listenyzen-navbar .nav.navbar-nav.navbar-right {
	margin-right: -21px;
}

.navbar.navbar-default.listenyzen-navbar .nav.navbar-nav.navbar-right .dropdown a {
	color: #0084FF;
	font-size: 16px;
	transition: 0.3s;
}

/* .navbar.navbar-default.listenyzen-navbar .nav.navbar-nav.navbar-right .dropdown.open a,
.navbar.navbar-default.listenyzen-navbar .nav.navbar-nav.navbar-right .dropdown a:hover{
	background: #0084FF;
	color: #fff;
} */

.sound {
	float: right;
	margin-right: 20px;
	margin-top: 16px;
	font-size: 20px;
	cursor: pointer;
}

.navbar.navbar-default.listenyzen-navbar .nav.navbar-nav.navbar-right .dropdown .dropdown-menu a {
	font-size: 14px;
	background: #fff;
	color: #999999;
	transition: 0.3s;
}

.navbar.navbar-default.listenyzen-navbar .nav.navbar-nav.navbar-right .dropdown .dropdown-menu a:hover {
	background: #0084FF;
	color: #fff;
}

.navbar.navbar-default.listenyzen-navbar .nav.navbar-nav.navbar-right .dropdown .dropdown-menu a i {
	margin-right: 5px;
	color: #0084FF
}

.navbar.navbar-default.listenyzen-navbar .nav.navbar-nav.navbar-right .dropdown .dropdown-menu a:hover i {
	color: #ffff
}

#wrapper.toggled #page-content-wrapper {
	position: relative;
	margin-right: 0px;
}

.fixed-brand {
	width: auto;
}

.page-content {
	margin: 10px 10px 0 10px;
	min-height: 400px;
}

.fixed-brand {
	width: 230px;
}

#wrapper.toggled {
	padding-left: 0;
}

#page-content-wrapper {
	overflow-x: hidden;
	position: relative;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

#wrapper.toggled #page-content-wrapper {
	position: relative;
	margin-right: 0;
	padding-left: 250px;
}

#wrapper.toggled-2 #page-content-wrapper {
	position: relative;
	margin-right: 0;
	/* margin-left: -155px; */
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	width: auto;
}


/*************************************************************************
Social Activities Management Application Styles
**************************************************************************/

.social-activities-management {
	position: relative;
	overflow: hidden;
	top: 0px;
	margin: auto;
	padding: 0;
	/* box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .06), 0 2px 5px 0 rgba(0, 0, 0, .2); */
	border: 1px solid #dddfe2;
	border-radius: 3px;
}

.social-activities-area {
	background-color: #fff;
	height: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .06), 0 2px 5px 0 rgba(0, 0, 0, .2);
}

.left-section {
	padding: 0;
	margin: 0;
	height: 100%;
}

.side-one {
	padding: 0;
	margin: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	position: relative;
	display: block;
	top: 0;
}

.heading {
	padding: 10px 16px 10px 15px;
	margin: 0;
	height: 60px;
	width: 100%;
	background-color: #eee;
	z-index: 1000;
}

.heading-avatar {
	padding: 0;
	cursor: pointer;
}

.heading-avatar-icon img {
	border-radius: 50%;
	height: 40px;
	width: 40px;
}

.heading-name {
	padding: 0 !important;
	cursor: pointer;
}

.heading-name a {
	text-decoration: none;
	color: #616161;
	font-size: 14px;
	margin-top: 3px;
}

.heading-name-meta {
	font-weight: 700;
	font-size: 100%;
	padding: 5px;
	padding-bottom: 0;
	text-align: left;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: #000;
	display: block;
}

.heading-online {
	display: none;
	padding: 0 5px;
	font-size: 12px;
	color: #93918f;
}

.heading-compose {
	padding: 0;
}

.heading-compose i {
	text-align: center;
	padding: 5px;
	color: #93918f;
	cursor: pointer;
}

.heading-dot {
	padding: 0;
	margin-left: 10px;
}

.heading-dot i {
	text-align: right;
	padding: 5px;
	color: #93918f;
	cursor: pointer;
}

.heading-dot .dropdown-menu {
	right: -10px;
	top: 50px;
}

.heading-dot .dropdown {
	display: block !important;
}

.btn-group.open .dropdown-toggle {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.app-navigation {
	padding: 0 !important;
	margin: 0 !important;
	width: 100%;
}

.app-navigation .app-navigation-inner {
	height: 100%;
	width: 100%;
	background-color: #eeeeee;
}

.app-navigation .app-navigation-inner input:focus {
	outline: none;
	border: none;
	box-shadow: none;
}

.app-navigation .app-navigation-inner ul {
	margin-bottom: 0;
}

.app-navigation .app-navigation-inner ul li button.btn {
	background: transparent;
	outline: none;
	border: none;
	box-shadow: none;
	border-radius: 0px;
	padding-top: 15px;
	padding-bottom: 15px;
}

.app-navigation .app-navigation-inner ul li button.btn.btn-messenger.active {
	border-bottom: 2px solid #2196F3;
}

.app-navigation .app-navigation-inner ul li button.btn.btn-facebook.active {
	border-bottom: 2px solid #475993;
}

.app-navigation .app-navigation-inner ul li button.btn.btn-whatsapp.active {
	border-bottom: 2px solid #55CD6C
}

.app-navigation .app-navigation-inner ul li button.btn.btn-instagram.active {
	border-bottom: 2px solid #C21B73
}

.app-navigation .app-navigation-inner ul li button.btn.btn-viber.active {
	border-bottom: 2px solid #665CA7
}

.searchBox {
	padding: 0 15px !important;
	margin: 0 !important;
	width: 100%;
}

.searchBox .col-sm-3 {
	float: right !important;
}

.searchBox-inner {
	padding: 10px !important;
}

.searchBox-inner .form-group {
	margin-bottom: 0
}

.searchBox-inner input:focus {
	outline: none;
	border: 1px solid #999;
	box-shadow: none;
}

.sideBar {
	padding: 0 !important;
	margin: 0 !important;
	background-color: #fff;
	overflow-y: auto;
	height: calc(100% - 120px);
	height: 484px;
}

.sideBar .uactive {
	background-color: #f2f2f2;
	pointer-events: none;
}

.resolve-switch {
	position: relative;
	display: block;
	width: 60px;
	height: 27px;
	margin: 12px auto;
}

.resolve-switch input {
	display: none;
}

.label-switch {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
	border-radius: 34px;
	margin-bottom: 0
}

.label-switch:before {
	position: absolute;
	content: "";
	height: 24px;
	width: 24px;
	left: 2px;
	bottom: 1px;
	background-color: white;
	transition: .4s;
	top: 1px;
	border-radius: 50%;
}

input:checked+.label-switch.sucess {
	background-color: #00C853;
}

input:checked+.label-switch.warn {
	background-color: #fc7814;
}

input:checked+.label-switch:before {
	-webkit-transform: translateX(32px);
	-ms-transform: translateX(32px);
	transform: translateX(32px);
}

.sideBar-body {
	position: relative;
	padding: 5px !important;
	border-bottom: 1px solid #f7f7f7;
	height: auto;
	margin: 0 !important;
	cursor: pointer;
	transition: 0.3s;
}

.sideBar-body:hover {
	background-color: #f2f2f2;
}

.sideBar-body .sideBar-avatar {
	text-align: center;
	padding: 0 !important;
	pointer-events: none;
}

.avatar-icon img {
	border-radius: 50%;
	border: 1px solid #ccc;
	height: 40px;
	width: 40px;
}

.sideBar-main {
	padding: 0 !important;
	pointer-events: none;
}

.sideBar-main .row {
	padding: 0 !important;
	margin: 0 !important;
}

.sideBar-name {
	padding: 10px !important;
}

.name-meta {
	font-size: 100%;
	padding: 1% !important;
	text-align: left;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: #000;
}

.message-meta {
	color: #616161;
}

.sideBar-time {
	padding: 10px !important;
}

.time-meta {
	text-align: right;
	font-size: 12px;
	padding: 1% !important;
	color: rgba(0, 0, 0, .4);
	vertical-align: baseline;
}

.time-meta.resolved {
	color: #42B72A;
	float: right;
}

.time-meta.unresolved {
	color: red;
	float: right;
}

.time-meta.auto {
	color: #f49842;
	float: right;
	margin-right: 10px;
}


/*New Message*/

.newMessage {
	padding: 0 !important;
	margin: 0 !important;
	height: 100%;
	position: relative;
	left: -100%;
}

.newMessage-heading {
	padding: 10px 16px 10px 15px !important;
	margin: 0 !important;
	height: 100px;
	width: 100%;
	background-color: #00bfa5;
	z-index: 1001;
}

.newMessage-main {
	padding: 10px 16px 0 15px !important;
	margin: 0 !important;
	height: 60px;
	margin-top: 30px !important;
	width: 100%;
	z-index: 1001;
	color: #fff;
}

.newMessage-title {
	font-size: 18px;
	font-weight: 700;
	padding: 10px 5px !important;
}

.newMessage-back {
	text-align: center;
	vertical-align: baseline;
	padding: 12px 5px !important;
	display: block;
	cursor: pointer;
}

.newMessage-back i {
	margin: auto !important;
}

.composeBox {
	padding: 0 !important;
	margin: 0 !important;
	height: 60px;
	width: 100%;
}

.composeBox-inner {
	height: 100%;
	width: 100%;
	padding: 10px !important;
	background-color: #fbfbfb;
}

.composeBox-inner input:focus {
	outline: none;
	border: none;
	box-shadow: none;
}

.compose-sideBar {
	padding: 0 !important;
	margin: 0 !important;
	background-color: #fff;
	overflow-y: auto;
	border: 1px solid #f7f7f7;
	height: calc(100% - 160px);
}


/*Conversation*/

.middle-section {
	padding: 0;
	margin: 0;
	height: 100%;
	border-left: 1px solid #eee;
	border-right: 1px solid #eee;

}

.message-container {
	padding: 0 !important;
	margin: 0 !important;
}

.messages:after {
	content: "";
	display: table;
	clear: both;
	height: 0;
	visibility: hidden;
}

.screen {
	background-color: #fff;
	height: 485px;
	width: 100%;
	margin: 0 auto;
}

.conversation {
	height: 100%;
	overflow: auto;
	padding: 20px 10px;
	padding-bottom: 0;
}


.messages {
	margin-bottom: 10px;

}

.messages-received .message {
	float: left;
	background-color: #ddd;
	position: relative;
}

.messages-received .message-tags {
	float: left;
	width: 100%;
}

.messages-received .message-tags span {
	padding: 4px 8px;
	/*background: #0084FF;*/
	background: linear-gradient(45deg, #1da1f2, #0e71c8);
	font-size: 12px;
	color: #fff;
	border-radius: 20px;
	margin-bottom: 5px;
	margin-right: 3px;
	display: inline-table;
	clear: both;
}


.messages-received .message-tags span.tags:last-child {
	margin-right: 0;
}

.messages-received .message-tags span i {
	cursor: pointer;
	margin-left: 5px;
}

.messages-sent .message {
	background-color: #0084FF;
	color: #fff;
}

.message {
	float: right;
	display: block;
	max-width: 50%;
	margin-bottom: 2px;
	clear: both;
	padding: 7px 13px;
	font-size: 12px;
	border-radius: 15px;
	line-height: 1.4;
}

.message .message-tools {
	position: absolute;
	right: 0px;
	top: -15px;
	display: none;
	transition: 0.9s;
}

.message:hover .message-tools {
	display: block;
	transition: 0.9s;
}

.message .message-tools span {
	width: 22px;
	height: 22px;
	display: inline-block;
	background: #fff;
	border-radius: 50%;
	box-shadow: 0 2px 10px #333 !important;
	text-align: center;
	cursor: pointer;
	transition: 0.9s;
}

.message .message-tools span i {
	color: #616161;
	font-size: 13px;
	line-height: 22px;
}

.message .message-tools.selected span.comment-tag i {
	color: #0084FF;
}

.message .message-tools.selected {
	display: block;
}

/*Reply Styles For Both Conversation and Post*/

.reply {
	width: 100%;
	height: 78px;
	background-color: #fff;
	padding: 0 !important;
	margin: 0 !important;
	z-index: 1000;
	border-top: 1px solid #E9EBEE;
}

.reply-placeholder {
	height: 80px;
}

.reply-emojis {
	padding: 5px !important;
}

.reply-emojis i {
	text-align: center;
	padding: 5px 5px 5px 5px !important;
	color: #93918f;
	cursor: pointer;
}

.reply-recording {
	padding: 5px !important;
}

.reply-recording i {
	text-align: center;
	padding: 5px !important;
	color: #93918f;
	cursor: pointer;
}

.reply-send {
	padding: 0;
	display: inline-block;
	margin: 0 10px 0 0;
}

.reply-send i.fa {
	margin-top: 14px;
	font-size: 20px;
	text-align: center;
	color: #ccc;
	cursor: pointer;
}

.reply-send i.fa:hover {
	color: #0084FF;
}

.reply-draft {
	padding: 0;
	display: inline-block;
	margin: 0 10px 0 0;
}

.reply-draft i.fa {
	margin-top: 14px;
	font-size: 20px;
	text-align: center;
	color: #ccc;
	cursor: pointer;
}

.reply-draft i.fa:hover {
	color: #0084FF;
}

.reply-main {
	padding: 0 !important;
}

.reply-main .text-bar-field textarea {
	width: 100%;
	resize: none;
	overflow-y: auto;
	padding: 5px !important;
	outline: none;
	border: none;
	text-indent: 5px;
	box-shadow: none;
	height: 74px;
	font-size: 12px;
}

.reply-main .text-bar-field textarea:focus {
	outline: none;
	border: none;
	text-indent: 5px;
	box-shadow: none;
}

.reply-main .text-bar-field input {
	width: 100%;
	resize: none;
	overflow-y: auto;
	padding: 5px !important;
	outline: none;
	border: none;
	text-indent: 5px;
	box-shadow: none;
	height: 50px;
	font-size: 12px;
}

.reply-main .text-bar-field input:focus {
	outline: none;
	border: none;
	text-indent: 5px;
	box-shadow: none;
}

.reply-tools {
	padding: 0;
	position: relative;
}

.reply-tools span {
	display: inline-block;
	margin-top: 13px;
	cursor: pointer;
	margin-right: 10px;
}

.reply-tools span i {
	transition: 0.3s;
}

.reply-tools span:nth-child(2) {
	margin: 13px 10px 0 10px;
}

.reply-tools span i.fa {
	font-size: 22px;
	color: #ccc
}

.reply-tools span i.fa:hover {
	color: #666
}

.reply-tools span.messagesend i.active:hover {
	color: #0084FF;
}

.reply-tools span.attach-file input[type="file"] {
	display: none;
}


.reply-tools .reply-tools span.attach-file .upload-attachment input[type="file"] {
	display: none;
}

.reply-tools span.attach-file .upload-attachment {
	display: inline-block;
	cursor: pointer;
}

.reply-tools span.quick-chat {
	position: relative;
}

.reply-tools span.messagesend {
	position: relative;
}

.reply-tools span.messagesend .active {
	color: #0084FF;
}

.quick-chat-item {
	position: fixed;
	/* left: 0; */
	bottom: 95px;
	width: 300px;
	max-height: 300px;
	/* margin: 0 auto; */
	z-index: 999;
	right: 26%;
	text-align: left;
	overflow-y: scroll;
	overflow-x: hidden;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.quick-chat-item .list-group-item {
	margin-bottom: 0px !important;
	/* background-color: transparent !important; */
	border-bottom: 1px solid #ddd;
	border-top: 0px !important;
	border-left: 0px !important;
	border-right: 0px !important;
	padding: 10px !important;
}

.quick-chat-item .list-group-item:first-child {
	border-radius: 0px !important;
}

.quick-chat-item .list-group-item:last-child {
	border-bottom: 0px !important;
	border-radius: 0px !important;
}

.quick-chat-item ul {
	margin-bottom: 0
}

.quick-chat-item ul li {
	text-align: left;
	transition: 0.3s;
}


.quick-chat-item ul li:hover {
	background: #0084FF;
	color: #fff;
}

.has-search .form-control-feedback {
	right: initial;
	left: 0;
	color: #ccc;
}

.has-search .form-control {
	padding-right: 12px;
	padding-left: 34px;
}


.conversation-section .heading,
.facebook-post-section .heading {
	height: 58px;
}

.facebook-post-section .reply-tools span.quick-chat .quick-chat-item {
	left: -172px;
}

.facebook-post-section .reply {
	height: 80px;
}

/*Profile Details*/

.right-section {
	padding: 0;
	height: 100%;
	position: relative;
	background: #fff;
	overflow: scroll;
}

.profile-details {
	margin-top: 30px;
}

.profile-details .profile-id {
	border-bottom: 1px solid #eee;
}

.profile-details .profile-id .avatar {
	position: relative;
}

.profile-details .profile-id .avatar img {
	position: relative;
	width: 80px;
	height: 80px;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .06), 0 2px 15px 0 rgba(0, 0, 0, .6);
	border-radius: 50%;
}

.profile-details .profile-id .avatar span.app-icon {
	position: absolute;
	right: 35%;
	bottom: 0
}

.profile-details .profile-id .avatar span.app-icon img {
	width: 20px;
	height: 20px;
	box-shadow: none;
}

.profile-details .profile-id .avatar span.app-icon-connected {
	position: absolute;
	left: 35%;
	bottom: 0
}

.profile-details .profile-id .avatar span.app-icon-connected img {
	width: 20px;
	height: 20px;
	box-shadow: none;
}

.profile-details .profile-id h3 {
	color: #616161;
	font-size: 16px;
}


.basic-information {
	border-bottom: 1px solid #eee;
	min-height: 95px;
}

.basic-information .information-details {
	padding: 15px;
}

.basic-information .information-details h3 {
	margin: 0;
	margin-bottom: 10px;
	color: #616161;
	font-size: 16px;
}

.basic-information .information-details ul {
	margin: 0;
	padding: 0;
	padding-left: 10px;
	list-style: none;
}

.basic-information .information-details li {
	color: #616161;
}

.basic-information .information-details li i {
	margin-right: 10px;
}

.profile-tags {
	margin-top: 15px;
	margin-bottom: 10px;
}

.profile-tags .profile-tag-details {
	padding: 0 15px;
}

.profile-tags .profile-tag-details h3 {
	margin: 0;
	margin-bottom: 10px;
	color: #616161;
	font-size: 16px;
}

.profile-tags .profile-tag-details h4 {
	margin: 0;
	margin-top: 15px;
	margin-bottom: 10px;
	color: #616161;
	font-size: 15px;
}

.profile-tags .profile-tag-details p {
	margin-bottom: 5px;
	color: #616161;
	font-size: 12px;
}

.profile-tags .profile-tag-details span.tags {
	padding: 5px 10px;
	/*background: #0084FF;*/
	background: linear-gradient(45deg, #1da1f2, #0e71c8);
	font-size: 12px;
	color: #fff;
	border-radius: 20px;
	margin-bottom: 5px;
	margin-right: 3px;
	display: inline-table;
	clear: both;
}

.profile-tags .profile-tag-details span.tags:last-child {
	margin-right: 0;
}

.profile-tags .profile-tag-details span i {
	cursor: pointer;
	margin-left: 5px;
}

.profile-tags .profile-tag-details a.add-tag {
	background: linear-gradient(45deg, #1da1f2, #0e71c8);
	box-shadow: 0px 4px 10px rgba(14, 113, 200, 0.7);
	color: #fff;
}

/*Facebook Comments*/

.facebook-post-content {
	overflow-y: auto;
	height: 490px;
}

.facebook-post-content .facebook-post-content-inner {
	overflow-y: auto;
	overflow-x: hidden;
	height: 100%;
}

.facebook-post-content .facebook-post-content-inner .facebook-embed-post {
	padding: 8px;
}

.facebook-card-content {
	margin: 0 auto;
	width: 100%;
}

.facebook-card-reactions {
	padding: 12px;
	margin-bottom: 15px
}

.facebook-card-reactions .reactions {
	text-decoration: none;
}

.facebook-card-reactions .reactions img {
	width: 20px;
	height: 20px;
	border-radius: 50%;
}

.facebook-reaction-love img {
	margin-left: -7px;
	border: 2px solid #fff;
	height: 25px !important;
	width: 23px !important;
}

.facebook-reaction-haha img {
	margin-left: -7px;
	border: 2px solid #fff;
	height: 25px !important;
	width: 23px !important;
}

.facebook-reaction-wow img {
	margin-left: -7px;
	border: 2px solid #fff;
	height: 25px !important;
	width: 23px !important;
}

.facebook-reaction-sad img {
	margin-left: -7px;
	border: 2px solid #fff;
	height: 25px !important;
	width: 23px !important;
}

.facebook-reaction-angry img {
	margin-left: -7px;
	border: 2px solid #fff;
	height: 25px !important;
	width: 23px !important;
}

.commentImage {
	clear: both;
	margin: 10px;
	width: 100px;
	height: 100px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	border-radius: 4px;
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .1);
	cursor: pointer;
}

.tooltip.reactior-list ul {
	list-style: none;
	padding: 0;
	margin: 0;
}


.people-liked {
	text-decoration: none;
	color: #365899;
	margin-left: 4px;
	font-size: 12px;
	width: 58%
}


.best-comments a {
	color: #999999;
	text-decoration: none;
	font-size: 12px;
}

.best-comments i {
	margin-left: 4px;
}

hr {
	border: none;
	margin: 0 auto;
	border-bottom: 1px solid #e1e2e3;
	margin-left: 12px;
	margin-right: 12px;
	margin-bottom: 6px;
}

.shared {
	text-decoration: none;
	font-size: 12px;
	color: #365899;
	margin-top: 12px;
	padding-left: 12px;
	margin-bottom: 6px;
}

.facebook-card-comments {
	min-height: 32px;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 12px;
	padding-right: 6px;
}

.input-comment {
	width: 100%;
	display: flex;
	align-items: center;
	margin: 0 auto;
	position: relative;
}

.facebook-card-user-image {
	margin-right: 6px;
}

.input-comment img {
	height: 32px;
	width: 32px;
	border-radius: 50%;
	float: left;
}

.facebook-card-input-box {
	width: 100%;
	background-color: #fff;
	border: 1px solid #dddfe2;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	border-radius: 17px;
	padding: 5px;
	float: right;
	margin-right: 5px;
	margin-top: 8px;
}

.facebook-card-input {
	font-size: 13px;
	text-align: left;
	display: block;
	color: #ccc;
	outline: none;
	border: none;
	width: 78%;
}

.comment-box-icons {
	text-decoration: none;
	color: #ccc;
	padding-left: 12px;
	display: flex;
	justify-content: flex-end;
}

.facebook-card-user-image {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	float: left;
	margin-top: 1px;
}

.main-comment {
	/* margin-top: 20px; */
	padding-top: 20px;
	/*overflow: hidden;*/
}

.main-comment .col-sm-11 {
	margin-bottom: 10px;
}

.main-comment .facebook-comment {
	position: relative;
	margin: 0 auto;
	width: 400.38px;
	min-height: 35px;
	background-color: #eee;
	border-radius: 20px;
	width: auto;
	font-size: 13px;
	margin-left: 5px;
	padding: 6px 15px;
}


.main-comment .facebook-card-comment-reaction {
	height: 20px;
	background-color: #fff;
	align-items: center;
	border: 0.5px solid #dddfe2;
	display: flex;
	border-radius: 10px;
	padding-left: 2px;
	padding-right: 3px;
	font-size: 11px;
	text-decoration: none;
	color: #898f9c;
	box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.2);
	outline: 1px #fff;
	position: absolute;
	right: 10px;
	bottom: -10px;
	color: #898f9c !important;
}

.main-comment .facebook-card-comment-reaction img {
	width: 16px;
	height: 16px;
	border-radius: 50%;
	margin-right: 2px
}


.main-comment .hiddenComment,
.reply-comment .hiddenComment {
	opacity: 0.5;
}

.main-comment .deleted,
.reply-comment .deleted {
	text-decoration: line-through;
}

.main-comment .facebook-comment .comment-tools {
	position: absolute;
	right: -27px;
	top: -15px;
	display: none;
	transition: 0.9s;
	width: 20%;
}

.main-comment .facebook-comment:hover .comment-tools {
	display: block;
	transition: 0.9s;
}

.main-comment .facebook-comment .comment-tools span {
	width: 22px;
	height: 22px;
	display: inline-block;
	background: #fff;
	border-radius: 50%;
	box-shadow: 0 2px 10px #333 !important;
	text-align: center;
	cursor: pointer;
	transition: 0.9s;
}

.main-comment .facebook-comment .comment-tools span i {
	color: #616161;
	font-size: 13px;
	line-height: 22px;
}

.main-comment .facebook-comment .comment-tools.selected span.comment-tag i {
	color: #0084FF;
}

.main-comment .facebook-comment .comment-tools.selected {
	display: block;
}

.main-comment .comment-tags {
	margin-top: 10px;
	margin-bottom: 10px;
	margin-left: 10px;
}

.main-comment .comment-tags span {
	display: inline;
	padding: 5px 10px;
	/*background: #0084FF;*/
	background: linear-gradient(45deg, #1da1f2, #0e71c8);
	margin-right: 5px;
	color: #fff;
	border-radius: 20px;
}

.main-comment .comment-tags span i {
	margin-left: 3px;
	cursor: pointer;
}


.reply-comment {
	margin-top: 20px;
	margin-left: 25px;
}

.reply-comment .col-sm-11 {
	margin-bottom: 10px;
}

.reply-comment .facebook-comment {
	position: relative;
	margin: 0 auto;
	width: 400.38px;
	min-height: 35px;
	background-color: #eee;
	border-radius: 20px;
	width: auto;
	font-size: 13px;
	margin-left: 0px;
	padding: 6px 15px;
}


.reply-comment .facebook-card-comment-reaction {
	height: 20px;
	background-color: #fff;
	align-items: center;
	border: 0.5px solid #dddfe2;
	display: flex;
	border-radius: 10px;
	padding-left: 2px;
	padding-right: 3px;
	font-size: 11px;
	text-decoration: none;
	color: #898f9c;
	box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.2);
	outline: 1px #fff;
	position: absolute;
	right: 10px;
	bottom: -10px;
}

.reply-comment .facebook-card-comment-reaction img {
	width: 16px;
	height: 16px;
	border-radius: 50%;
	margin-right: 2px
}

.reply-comment .facebook-comment .comment-tools {
	position: absolute;
	right: -27px;
	top: -15px;
	display: none;
	transition: 0.9s;
	width: 20%;
}

.reply-comment .facebook-comment:hover .comment-tools {
	display: block;
	transition: 0.9s;
}

.reply-comment .facebook-comment .comment-tools span {
	width: 22px;
	height: 22px;
	display: inline-block;
	background: #fff;
	border-radius: 50%;
	box-shadow: 0 2px 10px #333 !important;
	text-align: center;
	cursor: pointer;
	transition: 0.9s;
}

.reply-comment .facebook-comment .comment-tools span i {
	color: #616161;
	font-size: 13px;
	line-height: 22px;
}

.reply-comment .facebook-comment .comment-tools.selected span.comment-tag i {
	color: #0084FF;
}

.reply-comment .facebook-comment .comment-tools.selected {
	display: block;
}

.reply-comment .comment-tags {
	margin-top: 10px;
	margin-bottom: 10px;
	margin-left: 10px;
}

.reply-comment .comment-tags span {
	display: inline;
	padding: 5px 10px;
	/*background: #0084FF;*/
	background: linear-gradient(45deg, #1da1f2, #0e71c8);
	margin-right: 5px;
	color: #fff;
	border-radius: 20px;
}

.reply-comment .comment-tags span i {
	margin-left: 3px;
	cursor: pointer;
}

.page.main-comment {
	margin-left: 0;
	overflow: visible;
}

.page.main-comment .facebook-comment {
	position: relative;
	margin: 0 auto;
	width: 400.38px;
	min-height: 35px;
	/*background-color: #0084FF;*/
	background: linear-gradient(45deg, #1da1f2, #0e71c8);
	border-radius: 20px;
	width: auto;
	font-size: 13px;
	margin-left: 0px;
	padding: 10px 15px;
	color: #fff;
}

.page.main-comment .facebook-comment a {
	color: #fff;
}

.page.reply-comment {
	margin-left: 0;
	overflow: visible;
	margin-left: 25px
}

.page.reply-comment .facebook-comment {
	position: relative;
	margin: 0 auto;
	width: 400.38px;
	min-height: 35px;
	background-color: #0084FF;
	border-radius: 20px;
	width: auto;
	font-size: 13px;
	margin-left: 0px;
	padding: 10px 15px;
	color: #fff;
}


.page.reply-comment .facebook-card-comment-reaction {
	color: #898f9c !important;
}

.page.reply-comment .facebook-comment a {
	color: #fff;
}

.facebook-comment a {
	text-decoration: none;
	color: #365899;
}

.facebook-card-content-user {
	font-weight: bold;
	margin-right: 5px;
}

.facebook-card-tagged-user {
	margin-right: 5px;
}

.reaction-buttons {
	margin-top: 5px;
	margin-left: 10px;
	font-size: 12px;
	color: #898f9c;
}

.reaction-buttons a {
	text-decoration: none;
	padding: 4px;
	display: inline;
}

.facebook-card-comment-option {
	color: #365899;
	font-size: 12px;
}

.facebook-card-comment-date {
	color: #898f9c;
}

.comments-content {
	width: 100%;
	height: 32px;
}

.text {
	width: 100%;
}

.reaction {
	position: absolute;
	display: inline;
	left: 520px;
	margin-top: 38px;
}

/*Company Dashboard Styles*/

.add-project {
	background: #fff;
	height: 200px;
	margin-top: 50px;
	border-radius: 3px;
	/* box-shadow: 0 10px 20px rgba(0,0,0,.1); */
	position: relative;
}

.add-project-icon {
	position: relative;
	top: 45px;
}


.add-project-icon a {
	width: 120px;
	height: 120px;
	display: block;
	margin: 0 auto;
	background: #0084ff;
	color: #ddd;
	border-radius: 50%;
	text-decoration: none;
	box-shadow: 0px 7px 30px rgba(43, 98, 169, 0.5);
}

.add-project-icon a span {
	font-size: 90px;
	color: #fff;
	line-height: 105px;
}


.add-project-icon img {
	box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
	border-radius: 50%;
}

.user-card-container {
	padding: 5px;
}

.add-project-card-container {
	padding-left: 5px;
	padding-right: 5px;
}

.user-card {
	min-height: 180px;
	background: #fff;
	border: 1px solid #dddfe2;
	/* box-shadow:0 10px 20px rgba(0,0,0,.1); */
	border-radius: 3px;
	transition: 0.5s;
	margin-top: 45px;
}

.user-card .card {
	text-align: center;
	padding: 20px;
	box-sizing: border-box;
	width: 100%;
}

.user-card .card .image {
	margin-top: -60px;
}


.user-card .card .image img {
	width: 80px;
	height: 80px;
	margin: 0 auto;
	border-radius: 50%;
	box-shadow: 0px 5px 15px 0px #ddd, 0px 0px 0px 7px rgba(236, 233, 230, 0.9);

}

.user-card .card .card-text {
	height: 80px;
}

.user-card .card .card-text h2 {
	font-size: 16px;
	line-height: 20px;
	color: #262626;
	margin: 20px auto 0;
}

.user-card .card .card-text p {
	font-size: 14px;
	background: #0084FF;
	color: #fff;
	display: inline-block;
	margin-top: 5px;
	padding: 4px 10px;
	border-radius: 15px;
	margin-bottom: 5px
}

.user-card .card .card-text p {
	color: #fff;
}

.user-card .card .card-text span.count {
	font-size: 10px;
	background: #EA4335;
	color: #fff;
	display: inline-block;
	margin-top: 0px;
	padding: 4px 10px;
	border-radius: 15px;
}

.user-card .card .card-text span.acount {
	font-size: 10px;
	background: #f49842;
	color: #fff;
	display: inline-block;
	margin-top: 0px;
	padding: 4px 10px;
	border-radius: 15px;
	margin-left: 5px;
}

.user-card .card .card-buttons ul {
	margin: 0;
	padding: 0;
}

.user-card .card .card-buttons ul li {
	list-style: none;
	float: left;
}

.user-card .card .card-buttons ul li a {
	display: block;
	color: #aaa;
	margin: 0 10px;
	font-size: 20px;
	transition: 0.5s;
	text-align: center;
}

.user-card .card .card-buttons ul li:hover a {
	color: #e91e63;
	transform: rotateY(360deg);
}

.icon {
	display: inline-block;
	width: 10px;
	height: 10px;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;
}

.card-buttons {
	position: relative;
}

.card-buttons-inner {
	margin-top: 10px;
	text-align: center;
}

.card-btn {
	display: inline-flex;
	width: 30px;
	height: 30px;
	margin-right: 5px;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	color: #fff;
	background: #0084FF;
	box-shadow: 0px 7px 30px rgba(43, 98, 169, 0.5);
	position: relative;
	font-size: 14px;
	flex-shrink: 0;
	transition: all .3s;
	text-decoration: none;
}

.card-btn:focus,
.card-btn:active,
.card-btn:visited {
	color: #fff;
	text-decoration: none
}

.card-btn:hover {
	color: #fff;
	text-decoration: none
}

.card-btn .fb-assigned {
	display: none;
}

.card-btn.disabled .fb-assigned {
	display: block;
}

.gdisabled {
	opacity: 0.5 !important;
	pointer-events: none;
}

.card-btn.facebook {
	background: linear-gradient(45deg, #3b5998, #0078d7);
	box-shadow: 0px 4px 10px rgba(43, 98, 169, 0.5);
}

.card-btn.instagram {
	background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
	box-shadow: 0px 4px 10px rgba(120, 64, 190, 0.6);
}

.card-btn.viber {
	background: #665CA7;
	box-shadow: 0px 4px 10px rgba(120, 64, 190, 0.6);
}

.card-btn.whatsapp {
	background: #4ea752;
	box-shadow: 0px 4px 10px rgba(76, 175, 80, 0.6);
}

.card-btn.assign-qm {
	background: linear-gradient(45deg, #5e35b1, #3F9EFC);
	box-shadow: 0px 4px 10px rgba(27, 86, 231, 0.7);
}

.card-btn.inbox {
	background: linear-gradient(45deg, #4ea752, #1b5e20);
	box-shadow: 0px 4px 10px rgba(76, 175, 80, 0.6);
}

.card-btn.report {
	background: linear-gradient(45deg, #ff6d00, #ff9800);
	box-shadow: 0px 4px 10px rgba(8, 7, 6, 0.4);
}

.card-btn.messenger {
	background: linear-gradient(45deg, #2196f3, #64b5f6);
	box-shadow: 0px 4px 10px rgba(21, 101, 192, 0.6);
}

.card-btn .icon-font {
	display: inline-flex;
}

.card-btn .fb-assigned {
	position: absolute;
	top: -11px;
	right: -3px;
	color: #64dd17;
	font-size: 18px;
}

.profile-card-ctr {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
}

.card-btn:hover {
	transform: scale(1.1);
}

/*Tbar Page Styles*/

.analytics-dashboard .trainer-control-bar {
	box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.14), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);
	display: inline-block;
	width: 100%;
	margin-bottom: 15px;
	background: #fff;
	width: 100%;
	display: block;
	height: 60px;
}

.analytics-dashboard .trainer-control-bar .btn {
	background: linear-gradient(45deg, #1da1f2, #0e71c8);
	box-shadow: 0px 4px 10px rgba(14, 113, 200, 0.7);
	color: #fff;
	margin-left: 10px;
}

.analytics-dashboard .trainer-control-bar .left-side {
	display: block;
	float: left;
	width: 20%;
	height: 100%;
	border-radius: 5px 0 0 5px;
	/*box-shadow: 0 14px 26px -12px rgba(0,0,0,0.14), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2);*/
}

.analytics-dashboard .trainer-control-bar .right-side {
	float: right;
	text-align: right;
	padding: 10px;
	width: 80%;
	height: 100%;
	border-radius: 0 5px 5px 0;
}

/*Analytics Dashboard Page Styles*/

.analytics-dashboard .analytics-control-bar {
	box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.14), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);
	display: inline-block;
	width: 100%;
	margin-bottom: 15px;
}

.analytics-dashboard .analytics-control-bar .btn {
	background: linear-gradient(45deg, #1da1f2, #0e71c8);
	box-shadow: 0px 4px 10px rgba(14, 113, 200, 0.7);
	color: #fff;
}

.analytics-dashboard .analytics-control-bar .left-side-control {
	float: left;
	width: 88%;
	background: #fff;
	border-radius: 5px 0 0 5px;
	/*box-shadow: 0 14px 26px -12px rgba(0,0,0,0.14), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2);*/
}

.analytics-dashboard .analytics-control-bar .right-side-control {
	float: left;
	width: 12%;
	background: #fff;
	border-radius: 0 5px 5px 0;
}

.analytics-dashboard .analytics-control-bar .right-side-control .run-query {
	padding: 9px 18px 16px 0
}

.user-intent-analysis .btn,
.topic-analysis .btn {
	background: linear-gradient(45deg, #1da1f2, #0e71c8);
	box-shadow: 0px 4px 10px rgba(14, 113, 200, 0.7);
	color: #fff;
	margin-bottom: 10px;
}

.analytics-dashboard .container-fluid {
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 10px;
}

.report-page .container-fluid {
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 10px;
}

.company-dashboard-content .container-fluid {
	margin-bottom: 10px;
}

.analytics-dashboard-content {
	background: #fff;
	padding: 15px;
	border: 1px solid #dddfe2;
	border-radius: 3px;
}

.analytics-dashboard-content .panel.panel-default {
	/* box-shadow: 0 14px 26px -12px rgba(0,0,0,0.14), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2); */
	border: 1px solid #dddfe2;
	border-radius: 3px;
	height: 100%;
}

.analytics-dashboard-content .panel-heading {
	background: linear-gradient(45deg, #0e71c8, #1da1f2);
}

.analytics-dashboard-content .panel-heading .panel-title {
	color: #fff;
}


.analytics-dashboard-content .analytics-cards .analytics-card-item {
	box-shadow: 2px 2px 10px #DADADA;
	padding: 20px 10px;
	background-color: #fff;
	border-radius: 5px;
	transition: .3s linear all;
	margin-bottom: 15px;
	height: 120px;
}

.analytics-dashboard-content .analytics-cards .analytics-card-item h4 {
	margin: 0;
}

.analytics-dashboard-content .analytics-cards .analytics-card-item i {
	font-size: 5em;
	opacity: 0.2;
	position: relative;
	bottom: -15px;
	left: 0px;
}

.analytics-dashboard-content .analytics-cards .analytics-card-item img {
	font-size: 5em;
	opacity: 0.2;
	position: relative;
	bottom: -15px;
	left: 0px;
}

.analytics-dashboard-content .analytics-cards .analytics-card-item .count-numbers {
	position: absolute;
	right: 28px;
	bottom: 50px;
	font-size: 32px;
	display: block;
}

.analytics-dashboard-content .analytics-cards .analytics-card-item .count-name {
	position: absolute;
	right: 35px;
	bottom: 25px;
	/* font-style: italic; */
	text-transform: capitalize;
	display: block;
	font-size: 18px;
}

.analytics-dashboard-content .analytics-cards .analytics-card-item.query-received {
	background: linear-gradient(60deg, #0288d1, #01579b);
	box-shadow: 0 14px 26px -12px rgba(9, 118, 180, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(9, 118, 180, .2);
	color: #FFF;
}

.analytics-dashboard-content .analytics-cards .analytics-card-item.query-liked {
	background: linear-gradient(60deg, #4080ff, #4267b2);
	box-shadow: 0 14px 26px -12px rgba(9, 118, 180, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(9, 118, 180, .2);
	color: #FFF;
}

.analytics-dashboard-content .analytics-cards .analytics-card-item.auto-replied {
	background: linear-gradient(60deg, #F36265, #961276);
	box-shadow: 0 14px 26px -12px rgba(9, 118, 180, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(9, 118, 180, .2);
	color: #FFF;
}

.analytics-dashboard-content .analytics-cards .analytics-card-item.query-received .count-numbers {
	bottom: 25px;
}


.analytics-dashboard-content .analytics-cards .analytics-card-item.query-resolved {
	background: linear-gradient(60deg, #4ea752, #1b5e20);
	box-shadow: 0 14px 26px -12px rgba(76, 175, 80, .14), 0 4px 23px 0 rgba(76, 175, 80, .2), 0 8px 10px -5px rgba(76, 175, 80, .12);
	color: #FFF;
}

.analytics-dashboard-content .analytics-cards .analytics-card-item.query-unresolved {
	background: linear-gradient(60deg, #da3a36, #bf360c);
	box-shadow: 0 14px 26px -12px rgba(244, 67, 54, .42), 0 4px 23px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(244, 67, 54, .2);
	color: #FFF;
}

.analytics-dashboard-content .analytics-cards .analytics-card-item.query-hidden {
	background: linear-gradient(60deg, #ff9800, #f5700c);
	box-shadow: 0 14px 26px -12px rgba(255, 152, 0, .14), 0 4px 23px 0px rgba(255, 152, 0, .2), 0 8px 10px -5px rgba(255, 152, 0, .12);
	color: #FFF;
}

.analytics-dashboard-content .analytics-cards .analytics-card-item.query-deleted {
	background: linear-gradient(60deg, #7e57c2, #4527a0);
	box-shadow: 0 14px 26px -12px rgba(69, 39, 160, .14), 0 4px 23px 0px rgba(69, 39, 160, .2), 0 8px 10px -5px rgba(69, 39, 160, .12);
	color: #FFF;
}

.analytics-dashboard-content .analytics-cards .analytics-card-item.query-response-time {
	background: linear-gradient(60deg, #607d8b, #455a64);
	box-shadow: 0 14px 26px -12px rgba(55, 71, 79, .14), 0 4px 23px 0px rgba(55, 71, 79, .2), 0 8px 10px -5px rgba(55, 71, 79, .12);
	color: #FFF;
}

.analytics-dashboard-content .analytics-cards .analytics-card-item.query-response-time .count-numbers {
	bottom: 25px;
}


.analytics-dashboard-content .qm-performence-table table.table thead tr th {
	text-align: center;
	color: #0E72C9;
	border-color: #eee;
}

.analytics-dashboard-content .qm-performence-table table.table>thead:first-child>tr:first-child>th {
	border-top: 1px solid #eee;
}

.analytics-dashboard-content .qm-performence-table table.table-bordered>thead>tr>td,
.analytics-dashboard-content .qm-performence-table table.table-bordered>thead>tr>th {
	border-bottom-width: 1px;
}

.analytics-dashboard-content .qm-performence-table table.table tbody tr td {
	text-align: center;
	font-size: 12px;
	border-color: #eee
}

/*Report Page Styles*/

.report-page .report-page-content .panel.panel-default {
	/* box-shadow: 0 14px 26px -12px rgba(0,0,0,0.14), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2); */
	border: 1px solid #dddfe2;
	border-radius: 3px;
}

.report-page .report-page-content .panel-heading {
	background: linear-gradient(45deg, #0e71c8, #1da1f2);
}

.report-page .report-page-content .panel-heading .panel-title {
	color: #fff;
}

.report-page .report-page-content .report-page-table table.table {
	margin-bottom: 0;
}

.report-page .report-page-content .report-page-table table.table thead tr th {
	text-align: center;
	color: #0E72C9;
	border-color: #eee;
}

.report-page .report-page-content .report-page-table table.table>thead:first-child>tr:first-child>th {
	border-top: 1px solid #eee;
}

.report-page .report-page-content .report-page-table table.table-bordered>thead>tr>td,
.report-page .report-page-content .report-page-table table.table-bordered>thead>tr>th {
	border-bottom-width: 1px;
}

.report-page .report-page-content .report-page-table table.table tbody tr td {
	text-align: center;
	font-size: 12px;
	border-color: #eee
}

.message-attachment {
	padding: 0;
	background: #fff;

}

.message-attachment .single-image {
	width: 100px;
	height: 100px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .1);
	border-radius: 4px;
	cursor: pointer;

}

.message-attachments {
	padding: 0;
	background: #fff;
	width: 50%;
}

.message-attachments ul.gallery-image-items {
	list-style: none;
}

.messages-received .message-attachments ul.gallery-image-items {
	float: left;
	clear: both;
}

.messages-sent .message-attachments ul.gallery-image-items {
	float: right;
	clear: both;
}

.messages-received .message-attachment .single-image {
	float: left;
	clear: both;
}

.messages-sent .message-attachment .single-image {
	float: right;
	clear: both;
}

.message-attachments ul.gallery-image-items li {
	width: 100px;
	height: 100px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .1);
	border-radius: 4px;
	cursor: pointer;

}

.messages.messages-sent .message-time {
	margin: 0 !important;
	font-size: 12px;
	margin-right: 5px;
	color: #9a9a9a;
	clear: both
}

.messages.messages-received .message-time {
	margin: 0 !important;
	margin-left: 5px;
	font-size: 12px;
	color: #9a9a9a;
	clear: both
}

.message-attachment {
	padding: 0;
	background: #fff;

}

.message-attachment .single-image {
	width: 100px;
	height: 100px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .1);
	border-radius: 4px;
	cursor: pointer;

}

.message-attachments {
	padding: 0;
	background-color: #fff !important;
	width: 50%;
	padding-left: 5px;

}

.message-attachments ul.gallery-image-items {
	list-style: none;

}

.message-attachments ul.gallery-image-items li {
	width: 100px;
	height: 100px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .1);
	border-radius: 4px;
	cursor: pointer;

}

.messages-sent .message-attachments .attachment-text,
.messages-sent .message-attachment .attachment-text {
	max-width: 50%;
	padding: 7px 13px;
	font-size: 12px;
	border-radius: 15px;
	background-color: #0084FF;
	color: #fff;
	float: right;
	margin-bottom: 5px;
}

.messages-received .message-attachments .attachment-text,
.messages-received .message-attachment .attachment-text {
	max-width: 50%;
	padding: 7px 13px;
	font-size: 12px;
	border-radius: 15px;
	background-color: #ddd;
	float: left;
	margin-bottom: 5px;
}

/****************************************************************************************************************************
													Modal Styles
****************************************************************************************************************************/
.modal-header h4.modal-title {
	color: #fff
}

.modal-header {
	background: linear-gradient(-90deg, #1da1f2, #0e71c8);
}

/*Page list Modal Styles*/

.modal.fb-page-list .page-list-item {
	margin-bottom: 5px;
	padding-bottom: 5px;
	border-bottom: 1px solid #eee;
	overflow: hidden;
}

.modal.fb-page-list .page-list-item h2 {
	font-size: 15px;
	margin: 0;
	margin-top: 10px
}

.modal.fb-page-list .page-list-item .page-subscribe-btn .btn {
	background: linear-gradient(45deg, #1da1f2, #0e71c8);
	box-shadow: 0px 4px 10px rgba(14, 113, 200, 0.7);
	color: #fff;
	border: 0px;
	margin-bottom: 5px;
	display: inline-block;
	width: 100px;
	cursor: pointer;
}

.modal.fb-page-list .page-list-item .page-subscribe-btn .btn.subscribed {
	background: linear-gradient(45deg, #d5135a, #f05924);
	box-shadow: 0px 4px 10px rgba(223, 45, 70, 0.75);
	color: #fff;
	border: 0px;
	margin-bottom: 5px;
}


/*Query Manager Modal Styles*/

.modal-header button.close {
	color: #fff;
	opacity: 1;
}

.modal.qm-list .modal-body {
	padding: 10px 16px
}

.modal.qm-list .qm-list-item {
	margin-bottom: 5px;
	padding-bottom: 5px;
	border-bottom: 1px solid #eee;
}

.modal.qm-list .qm-list-item .qm-avatar img {
	width: 50px;
	height: 50px;
	border: 2px solid #0084FF;
	padding: 2px;
	border-radius: 50%;
}

.modal.qm-list .qm-list-item h2 {
	font-size: 15px;
	margin: 0;
	margin-top: 17px;
	margin-left: 10px;
}

.modal.qm-list .qm-list-item .qm-assign-btn {
	margin-top: 10px;
}

.modal.qm-list .qm-list-item .qm-assign-btn .btn {
	background: linear-gradient(45deg, #1da1f2, #0e71c8);
	box-shadow: 0px 4px 10px rgba(14, 113, 200, 0.7);
	color: #fff;
	border: 0px;
	margin-bottom: 5px;
	display: block;
}

.modal.qm-list .qm-list-item .qm-assign-btn .btn.assigned {
	background: linear-gradient(45deg, #d5135a, #f05924);
	box-shadow: 0px 4px 10px rgba(223, 45, 70, 0.75);
	color: #fff;
	border: 0px;
	margin-bottom: 5px;
}


/*Fixed Reply Modal Styles*/

.modal.fixed-reply .modal-body {
	padding: 10px 16px
}

.modal.fixed-reply .modal-footer .btn {
	background: linear-gradient(45deg, #1da1f2, #0e71c8);
	box-shadow: 0px 4px 10px rgba(14, 113, 200, 0.7);
	color: #fff;
	border: 0px;
	margin-bottom: 5px;
}


.add-project {
	background: #fff;
	height: 200px;
	/* height: 316px; */
	margin-top: 50px;
	border: 1px solid #dddfe2;
	border-radius: 3px;
	/* box-shadow: 0 10px 20px rgba(0,0,0,.1); */
	position: relative;
}

.add-project-icon {
	position: relative;
}

.add-project-icon img {
	box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
	border-radius: 50%;
}


/*Add Project Modal Styles*/

.add-project-modal .modal-body {
	padding: 10px 16px
}

.add-project-modal .modal-footer .btn {
	background: linear-gradient(45deg, #1da1f2, #0e71c8);
	box-shadow: 0px 4px 10px rgba(14, 113, 200, 0.7);
	color: #fff;
	border: 0px;
	margin-bottom: 5px;
}

.add-project-modal .upload-project-image .project-image img {
	width: 120px;
	height: 120px;
	box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
	border: 2px solid #ccc;
	border-radius: 3px;
}

.add-project-modal .upload-project-image .file-wrapper {
	margin-top: 20px
}

.add-project-modal .upload-project-image .file-wrapper .btn {
	background: linear-gradient(45deg, #1da1f2, #0e71c8);
	box-shadow: 0px 4px 10px rgba(14, 113, 200, 0.7);
	color: #fff;
	border: 0px;
	margin-bottom: 5px;
}

.add-project-modal .upload-project-image .file-wrapper input {
	cursor: pointer;
	font-size: 100px;
	height: 100%;
	filter: alpha(opacity=1);
	-moz-opacity: 0.01;
	opacity: 0.01;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 9;
}

.modal.add-tag .btn {
	background: linear-gradient(45deg, #1da1f2, #0e71c8);
	box-shadow: 0px 4px 10px rgba(14, 113, 200, 0.7);
	color: #fff;
	border: 0px;
	margin-bottom: 5px;
}

.hide {
	display: none;
}

.terms {
	display: inline-block;
	width: auto;
	height: inherit;
}

@media (min-width: 1600px) {
	.profile-details .profile-id .avatar span.app-icon {
		right: 40%;
	}

	.profile-details .profile-id .avatar span.app-icon-connected {
		left: 40%;
	}

	.toggled-2 .main-comment .facebook-comment {
		margin-left: -25px;
	}

	.page.main-comment .facebook-comment {
		padding: 10px 15px;
	}

	.main-comment .facebook-comment .comment-tools {
		right: -80px;
	}

	.reply-comment .facebook-comment .comment-tools {
		right: -78px;
	}

	.heading-name-meta a {
		margin-top: 6px;
		margin-left: -20px;

	}
}

/* code for no message or comment */
.instruction-container {
	width: 100%;
	height: auto;
	/*overflow: hidden;*/
}

.instruction,
.instruction-comment {
	width: 100%;
	height: auto;
	/*overflow: hidden;*/
}

.instruction h1,
.instruction-comment h1 {
	display: inline-block;
	margin: 0;
	margin-bottom: 30px;
	margin-right: 5px;
	position: relative;
	left: 0;
	top: 11px;
	color: #616161;
}

.instruction img.logo,
.instruction-comment img.logo {
	display: inline-block;
}

.instruction p,
.instruction-comment p {
	font-size: 16px;
	color: #616161;
	margin-bottom: 30px;
}

.instruction img.instruction-image,
.instruction-comment img.instruction-image {
	margin: 0 auto;
}

.terms-container {
	margin-top: 20px;
	padding: 0 10px;
}

.Terms p {
	font-size: 12px !important;
}

.Terms h1,
.Terms h2,
.Terms h3,
.Terms h4,
.Terms h5,
.Terms h6 {
	color: #616161;
	padding: 10px 0;
}

span.tags {
	cursor: pointer;
}

.comment-tags,
.message-tags {
	cursor: pointer;
}

.currentComment {
	background: #DA1236 !important;
	color: white !important;
}

.currentComment a.facebook-card-content-user {
	color: white !important;
}

.replyComment .facebook-comment {
	background: #4ea752 !important;
	color: white !important;
}

ul.navbar-right li.dropdown a img {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 1px solid #ddd;
}

.analytics-dashboard .react-datepicker-wrapper,
.react-datepicker__input-container {
	display: block !important;
}

.analytics-dashboard .react-datepicker-popper {
	top: 100% !important;
}

.react-datepicker__input-container .form-control {
	border-radius: 0px 4px 4px 0px !important;
}

.datefilter-inner .react-datepicker__input-container .form-control {
	border-radius: 4px !important;
	font-size: 10px;
}

.tag-cloud {
	flex: 1;
}

.tag-cloud>div {
	transition: 1.4s;
}

.aligncenter {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.searchBox-inner .form-control-feedback {
	pointer-events: auto !important;
	cursor: pointer;
}

.datefilter-inner,
.projectselect-inner {
	padding: 0 10px !important;
}

.datefilter-inner .react-datepicker {
	width: 223px;
}

.datefilter-inner .react-datepicker-wrapper {
	display: block !important;
}

.social-activities-area .btn {
	background: linear-gradient(45deg, #1da1f2, #0e71c8);
	color: #fff;
}

.panel-body .btn {
	background: linear-gradient(45deg, #1da1f2, #0e71c8);
	box-shadow: 0px 4px 10px rgba(14, 113, 200, 0.7);
	color: #fff;
	position: absolute;
	right: 5%;
	z-index: 99999;
}

.modal-body {
	max-height: 350px;
	overflow-y: auto;
}

.platformUcount {
	font-size: 8px;
	background: #EA4335;
	color: #fff;
	display: inline-block;
	position: absolute;
	margin-top: -10px;
	margin-left: -8px;
	padding: 4px 8px;
	border-radius: 15px;
}

.projectLogo {
	position: absolute;
	border-radius: 50%;
	margin-top: 10px;
	margin-left: 10px;
	border: 1px solid #ccc;
	width: 38px;
	height: 38px;
}

.disabled {
	pointer-events: none;
}

.messages-sent .message-attachment,
.messages-received .message-attachment {
	background-color: transparent !important;
}

.next .btn {
	background: linear-gradient(45deg, #1da1f2, #0e71c8);
	box-shadow: 0px 4px 10px rgba(14, 113, 200, 0.7);
	color: #fff;
}

.followup .btn {
	background: linear-gradient(45deg, #f4a341, #f49542);
	box-shadow: 0px 4px 10px rgba(244, 149, 66, 0.2);
	color: #fff;
	width: 43px;
}

.fcom .btn {
	background: linear-gradient(45deg, #20640f, #20640f);
	box-shadow: 0px 4px 10px rgba(244, 149, 66, 0.2);
	color: #fff;
	width: 43px;
}

.followup-user {
	background: #ffd581;
}

.freset .btn {
	background: linear-gradient(45deg, #f33c2e, #ec3224);
	color: #fff;
	width: 43px;
}

.fcomreset .btn {
	background: linear-gradient(45deg, #f33c2e, #ec3224);
	color: #fff;
	width: 43px;
}

a:hover {
	text-decoration: none;
}

.checkbox-holder {
	padding-top: 14px;
	font-size: 14px;
	color: #555;
	text-align: right;
}

.total-ur {
	position: absolute;
	top: 19%;
	right: 38%;
}

.total-ur span.count {
	font-size: 13px;
	background: #EA4335;
	color: #fff;
	display: inline-block;
	margin-top: 0px;
	padding: 6px 8px;
	border-radius: 15px;
}

.col-centered {
	float: none;
	margin: 0 auto;
}

input[class*="greyed"] {
	background-color: #eee;
}

.trainbtn {
	background: #90cb66;
}

.bhr {
	margin-bottom: 33px;
	margin-top: 40px;
	margin-left: 0px;
	margin-right: 0px;
	border-bottom: 1px solid #ddd;
	border-top: none;
	height: 0;
	background: #dadde1;
	color: #dadde1;
}

span.entity {
	border: 1px grey dotted;
	padding: 5px 8px;
	font-weight: bold;
	border-radius: 4px;
}

span.lookup_strategy {
	background-color: #4183c4;
	color: #fff;
	border-radius: 10px;
	padding: 3px 10px;
	margin-left: 5px;
}

.trial-alert {
	padding: 13px;
	background: #0084ff;
	height: auto;
	margin-top: 10px;
	border: 1px solid #dddfe2;
	border-radius: 3px;
	text-align: center;
	color: white;
}

.trial-expired-alert {
	padding: 13px;
	background: #3c8e3d;
	height: auto;
	margin-top: 10px;
	border: 1px solid #dddfe2;
	border-radius: 3px;
	text-align: center;
	color: white;
}

.pending-bill-alert {
	padding: 13px;
	background: #e40f54;
	height: auto;
	margin-top: 10px;
	border: 1px solid #dddfe2;
	border-radius: 3px;
	text-align: center;
	color: white;
}

.circle {
	position: relative;
	width: 10%;
	height: 0;
	padding: 5% 0;
	margin: 1em auto;
	border-radius: 50%;
	background: #a7cd80;
}

.circle-content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
}

.circle-content:before {
	content: '';
	vertical-align: middle;
	display: inline-block;
	width: 0;
	height: 100%;
}

.circle-content span {
	vertical-align: middle;
	display: inline-block;
}

.disablePoint {
	pointer-events: none;
	opacity: 0.5;
}

.hide_add_project_notice {
	position: absolute;
    top: 11%;
    left: 6%;
    width: 15%;
    text-align: center;
}

/*----------------------------------
    MEDIUM LAYOUT: 1400px
-----------------------------------*/
@media (min-width: 1600px) {


	.profile-details .profile-id .avatar span.app-icon {
		right: 40%;
	}

	.profile-details .profile-id .avatar span.app-icon-connected {
		left: 40%;
	}

	.toggled-2 .main-comment .facebook-comment {
		margin-left: -25px;
	}

	.page.main-comment .facebook-comment {
		padding: 10px 15px;
	}

	.main-comment .facebook-comment .comment-tools {
		right: -80px;
	}

	.reply-comment .facebook-comment .comment-tools {
		right: -78px;
	}

	.heading-name-meta a {
		margin-top: 6px;
		margin-left: -20px;

	}

	.analytics-dashboard .analytics-control-bar .left-side-control {
		float: left;
		width: 92%
	}

	.analytics-dashboard .analytics-control-bar .right-side-control {
		float: left;
		width: 8%
	}
}


/*----------------------------------
	MEDIUM LAYOUT: 1280px
-----------------------------------*/

@media (min-width: 992px) and (max-width: 1199px) {

	.analytics-dashboard .analytics-control-bar .left-side-control {
		float: left;
		width: 84%
	}

	.analytics-dashboard .analytics-control-bar .right-side-control {
		float: left;
		width: 16%
	}

}